
body {
  margin: 0px;
}

.banner {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  background-color: rgb(0, 49, 83);
  z-index: 100;
  opacity: 0.98;
}

.banner-col1 {
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.banner-col2 {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
}

.banner-col2-dropdown {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
}

.banner-col2-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.banner-col2-dropdown-content a {
  color: rgb(71, 71, 71);
  padding: 2px 16px;
  text-decoration: none;
  display: block;
}

.banner-col2-dropdown-content a:hover {background-color: #f1f1f1}

.banner-col2-dropdown:hover .banner-col2-dropdown-content {display: block;}

.banner-text {
  font-family: "CMU Serif Extra";
  font-size: 20px;
  text-decoration: none;
  line-height: 40px;
  color: white;
}

.spacer {
  height: 40px; /* Adjust according to your banner height */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .banner-text {
    font-size: 12px;
  }
}