* {
  box-sizing: border-box;
}

/* GLOBAL STYLES */

body,
.Scratchpad {
  font-family: Ubuntu;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-size: 16px;
}

button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  color: #08c;
}
button:hover {
  color: #04c;
}

.Scratchpad {
  display: flex;
}

/* SIDEBAR STYLES */

.scratchpad-sidebar {
  width: 25%;
  height: 200vh;
  border-right: 1px solid #ddd;
}

.scratchpad-sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
}

.scratchpad-sidebar-header h1 {
  margin: 0;
}

.scratchpad-sidebar-notes {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.scratchpad-sidebar-note {
  padding: 5px;
  cursor: pointer;
}
.sidebar-note-title {
  display: flex;
  justify-content: space-between;
}
.scratchpad-sidebar-note button {
  color: crimson;
}
.scratchpad-sidebar-note p {
  margin: 10px 0;
}
.scratchpad-sidebar-note small {
  display: block;
  color: #999;
}

.scratchpad-sidebar-note:hover {
  background: #ddd;
}
.scratchpad-sidebar-note.active,
.scratchpad-sidebar-note.active small {
  background: #08c;
  color: white;
}

/* NOTE EDITOR/PREVIEW (MAIN) STYLES */

.scratchpad-main {
  width: 75%;
  height: 100vh;
}
.scratchpad-main-note-edit,
.scratchpad-main-note-preview {
  height: 50vh;
}

.no-active-note {
  width: 70%;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  font-size: 2rem;
  color: #999;
}

/* Editing */
.scratchpad-main-note-edit {
  padding: 25px;
}

.scratchpad-main-note-edit input,
textarea {
  display: block;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
  height: calc(50vh - 130px);
  padding: 10px;
  resize: none;
  font-size: inherit;
  font-family: inherit;
}
.scratchpad-main-note-edit input {
  height: 50px;
  font-size: 2rem;
}

/* Preview */
.scratchpad-main-note-preview {
  border-top: 1px solid #ddd;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.02);
}

.preview-title {
  padding: 25px 25px 0 25px;
  margin: 0;
}

.markdown-preview {
  padding: 0 25px 25px 25px;
  font-size: 1rem;
  line-height: 2rem;
}